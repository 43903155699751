<template>
  <FormModal
    show-back-btn
    subtitle="Estas son las opciones que se adaptan a tus necesidades"
    title="¿Qué tipo de bomba de calor querrías?"
  >
    <template #body>
      <TypeOfHeatPumpFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfHeatPumpFormStep from '~/components/form/steps/typeOfHeatPump/TypeOfHeatPumpFormStep'

export default {
  components: {
    TypeOfHeatPumpFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Tipo de bomba de calor',
      headDescription:
        'Indique qué tipo de instalación de bomba de calor está buscando.',
      path: '/solicitudes-de-cotizacion/tipo-de-bomba-de-calor',
      progressValue: 75,
      checkoutStep: 5,
    }
  },
}
</script>
