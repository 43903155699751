<template>
  <TypeOfHeatPumpFormField :choices="choices" v-on="$listeners" />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import TypeOfHeatPumpFormField from 'chimera/all/components/form/fields/typeOfHeatPump/TypeOfHeatPumpFormField'
import { mapGetters } from 'vuex'

export default {
  name: 'TypeOfHeatPumpFormPartES',

  components: {
    TypeOfHeatPumpFormField,
  },

  /**
   * @returns {{defaultChoices: Selectable[], alternativeHybridChoices: Selectable[]}}
   */
  data() {
    return {
      defaultChoices: this.initializeChoices(),
      alternativeHybridChoices: this.initializeAlternativeChoices(),
    }
  },

  computed: {
    ...mapGetters({
      getData: 'lead/getData',
    }),

    /**
     * @returns {Selectable[]}
     */
    choices() {
      const typeOfExistingRadiatorStore = this.$store.getters['lead/getData'](
        'type-of-existing-radiator',
      )
      const intendsToReplaceRadiatorStore = this.$store.getters['lead/getData'](
        'intends-to-replace-radiator',
      )

      const typeOfExistingRadiator = Array.isArray(typeOfExistingRadiatorStore)
        ? typeOfExistingRadiatorStore[0].value
        : null
      const intendsToReplaceRadiator = Array.isArray(
        intendsToReplaceRadiatorStore,
      )
        ? intendsToReplaceRadiatorStore[0].value
        : null

      const hybridChoicesOnly =
        typeOfExistingRadiator ===
          this.$t('field.type-of-existing-radiator.options.regular-radiator') &&
        intendsToReplaceRadiator === this.$t('global.no')

      return hybridChoicesOnly
        ? this.alternativeHybridChoices
        : this.defaultChoices
    },
  },

  /**
   * @returns {Promise<void>}
   */
  async beforeMount() {
    if (this.choices === this.alternativeHybridChoices) {
      await this.$store.dispatch('lead/add', {
        key: 'type-of-heat-pump',
        value: this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
      })
    }
  },

  methods: {
    /**
     * @returns {Selectable[]}
     */
    initializeChoices() {
      // Initialization code for defaultChoices
      return [
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.air-air-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-air-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-air-heat-pump'),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.air-air-heat-pump',
            )}</strong> extrae el calor del aire exterior y lo hace circular hacia el interior para calentarlo. Se utiliza habitualmente en sistemas mini-split sin conductos.`,
          },
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.air-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-water-heat-pump'),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.air-water-heat-pump',
            )}</strong> transfiere el calor del aire al calentamiento de agua para la calefacción de espacios o para obtener agua caliente y es adecuada para radiadores y calefacción por suelo radiante.`,
          },
        ),
        new Selectable(
          this.$i18n.t(
            'field.type-of-heat-pump.options.ground-water-heat-pump',
          ),
          this.$i18n.t(
            'field.type-of-heat-pump.options.ground-water-heat-pump',
          ),
          this.$i18n.t(
            'field.type-of-heat-pump.options.ground-water-heat-pump',
          ),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.ground-water-heat-pump',
            )}</strong> extrae calor del suelo y lo transfiere al agua para calentarla. Es eficiente y sostenible, utilizando temperaturas estables del suelo.`,
          },
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.water-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.water-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.water-water-heat-pump'),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.water-water-heat-pump',
            )}</strong> utiliza agua subterránea como fuente de calor para los sistemas de calefacción. Hace circular agua a través de intercambiadores de calor para transferir la energía térmica.`,
          },
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.hybrid-heat-pump',
            )}</strong> combina calefacción tradicional (por ejemplo, gas o petróleo) con una bomba de calor, para obtener una calefacción y refrigeración eficientes y con calefacción de reserva si es necesario.`,
          },
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.unknown'),
          this.$i18n.t('field.type-of-heat-pump.options.unknown'),
          this.$i18n.t('field.type-of-heat-pump.options.unknown'),
        ),
      ]
    },

    /**
     * @returns {Selectable[]}
     */
    initializeAlternativeChoices() {
      // Initialization code for alternativeHybridChoices
      return [
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          {
            groupHelp:
              'Si deseas mantener los radiadores estándar, una bomba de calor híbrida sería la única y mejor opción para tu proyecto. Una bomba de calor híbrida funciona en combinación con una caldera de calefacción central de gas natural.',
          },
        ),
      ]
    },
  },
}
</script>
